import React, { useState } from 'react';

const numImagesPerRow = 5;

const ImageGrid = ({ imageUrls, productName, rowsToShow }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div>
            <img
                style={{ width: '100%', padding: 1 }}
                src={imageUrls[selectedIndex]}
                alt={productName}
            />
            <div style={{ minWidth: '100%', display: 'flex' }}>
                {imageUrls.map((imageUrl, index) => (
                    <img
                        style={{
                            width: `${100 / 5}%`,
                            margin: 1,
                            cursor: 'pointer',
                        }}
                        src={imageUrl}
                        alt={productName}
                        onClick={() => setSelectedIndex(index)}
                        key={imageUrl}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageGrid;
