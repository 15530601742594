import React from 'react'

import styles from './TextInput.module.scss'

const TextInput = ({ label, name, type, value, onChange, maxLength, cols, rows }) => (
	<div className={styles.input}>
		<div>{label}</div>
		<div>
			{type === 'textarea' ? (
				<textarea className={styles.textArea} name={name} value={value} onChange={onChange} maxLength={maxLength || 1000} cols={cols} rows={rows} />
			) : (
				<input name={name} type={type} value={value} onChange={onChange} maxLength={maxLength || 100} />
			)}
		</div>
	</div>
)

export default TextInput
