import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ProductCard from 'components/ProductCard';
import bigImg from 'img/sample_image.jpg';

import styles from 'views/views.module.scss';
import { dummyProducts } from 'utils/fakerService';

const Home = () => {
	const [productFeed, setProductFeed] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		// axios.get('http://localhost:3001/products').then((data) => {
		// 	setProductFeed(data.data);
		// 	setFilteredProducts(data.data);
		// });
		setProductFeed(dummyProducts);
		setFilteredProducts(dummyProducts);
	}, []);
	console.log(productFeed, filteredProducts);

	useEffect(() => {
		if (searchText) {
			console.log('the hell2' + searchText);
			setFilteredProducts(
				productFeed.filter((s) =>
					s.name.toLowerCase().includes(searchText.toLowerCase())
				)
			);
		} else {
			console.log('the hell');
			setFilteredProducts(productFeed);
		}
	}, [searchText, productFeed]);

	// come up with css for views
	// give image backgroundImage class and properties
	// iterate through sampleProducts to produce cards

	return (
		<div className='App'>
			{/* <img className={styles.backgroundImage} src={bigImg} /> */}
			<div className={styles.searchBar}>
				<input
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					placeholder={'search'}
				/>
			</div>
			<div className={styles.cardsContainer}>
				{filteredProducts.map((product) => (
					<ProductCard product={product} key={product.id} />
				))}
			</div>
		</div>
	);
};

export default Home;
