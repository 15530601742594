import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useCart = () => {
	const [cartItems, setCartItems] = useState([]);
	const addCartItem = (product) => {
		const existingEntry = cartItems.find((p) => p.id === product.id);
		if (existingEntry) {
			product.quantity = existingEntry.quantity + 1;
			setCartItems(
				cartItems.map((c) => {
					if (c.id === product.id) return product;
					else return c;
				})
			);
		} else {
			product.quantity = 1;
			setCartItems([...cartItems, product]);
		}
	};
	const removeCartItem = (product) => {
		setCartItems(cartItems.filter((c) => c.id !== product.id));
	};
	const clearWholeCart = () => {
		setCartItems([]);
	};
	return { cartItems, addCartItem, removeCartItem, clearWholeCart };
};

const CartContainer = createContainer(useCart);

export default CartContainer;
