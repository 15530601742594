import React, { useState, useEffect } from 'react';
import axios from 'axios';

import TextInput from 'components/TextInput';
import ButtonInput from 'components/ButtonInput';
import bigImg from 'img/sample_image.jpg';

import styles from './views.module.scss';

const Register = () => {
    useEffect(() => {
        window.location = `https://voodoomtn.auth.us-east-2.amazoncognito.com/login?client_id=ofnmqaun3a8nd3jalpjal3569&response_type=code&scope=email+openid+profile&redirect_uri=http://localhost:3000/login`;
    }, []);

    return <div>Rerouting to sign-in...</div>;
    // const [isRegister, setIsRegister] = useState(true);
    // const [email, setEmail] = useState('');
    // const [name, setName] = useState('');
    // const [password, setPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    // const [passwordsMatch, setPasswordsMatch] = useState(false);

    // useEffect(() => {
    //     if (password === confirmPassword) {
    //         setPasswordsMatch(true);
    //     } else {
    //         setPasswordsMatch(false);
    //     }
    // }, [password, confirmPassword]);

    // const validateEmail = () => {
    //     if (!email || !email.contains('@')) {
    //         return false;
    //     }
    // };

    // const validatePassword = () => {
    //     if (!password || password !== confirmPassword) {
    //         return false;
    //     }
    // };

    // const register = () => {
    //     // validate
    //     // call endpoint
    // };

    // const signIn = () => {
    //     // validate
    //     // call endpoint
    // };

    // return (
    //     <div className={styles.register}>
    //         <img className={styles.backgroundImage} src={bigImg} />
    //         <div className={styles.registerCard}>
    //             <div className={styles.header}>
    //                 {isRegister ? 'Sign up' : 'Sign in'}
    //             </div>
    //             <div className={styles.subHeader}>
    //                 {isRegister ? (
    //                     <div>
    //                         Already have an account?{' '}
    //                         <span
    //                             className={styles.link}
    //                             onClick={() => setIsRegister(false)}
    //                         >
    //                             Sign in!
    //                         </span>
    //                     </div>
    //                 ) : (
    //                     <div>
    //                         Don't have an account yet?{' '}
    //                         <span
    //                             className={styles.link}
    //                             onClick={() => setIsRegister(true)}
    //                         >
    //                             Join Us!
    //                         </span>
    //                     </div>
    //                 )}
    //             </div>
    //             {isRegister && (
    //                 <div className={styles.inputRow}>
    //                     <TextInput
    //                         label='Name'
    //                         name='name'
    //                         type='text'
    //                         value={name}
    //                         onChange={(e) => setName(e.target.value)}
    //                     />
    //                 </div>
    //             )}
    //             <div className={styles.inputRow}>
    //                 <TextInput
    //                     label='Email'
    //                     name='email'
    //                     type='email'
    //                     value={email}
    //                     onChange={(e) => setEmail(e.target.value)}
    //                 />
    //             </div>
    //             <div className={styles.inputRow}>
    //                 <TextInput
    //                     label='Password'
    //                     name='password'
    //                     type='password'
    //                     value={password}
    //                     onChange={(e) => setPassword(e.target.value)}
    //                 />
    //             </div>
    //             {isRegister && (
    //                 <div className={styles.inputRow}>
    //                     <TextInput
    //                         label='Confirm Password'
    //                         name='confirmPassword'
    //                         type='password'
    //                         value={confirmPassword}
    //                         onChange={(e) => setConfirmPassword(e.target.value)}
    //                     />
    //                 </div>
    //             )}
    //             {isRegister ? (
    //                 <div>
    //                     <ButtonInput
    //                         disabled={
    //                             !(name && email && password && passwordsMatch)
    //                         }
    //                         label='Register'
    //                         onClick={(e) => register()}
    //                     />
    //                 </div>
    //             ) : (
    //                 <div>
    //                     <ButtonInput
    //                         disabled={!(email && password)}
    //                         label='Sign in'
    //                         onClick={signIn}
    //                     />
    //                 </div>
    //             )}
    //         </div>
    //     </div>
    // );
};

export default Register;
