import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ShoppingCart } from '@material-ui/icons';

import CartContainer from 'context/CartContainer';
import VoodooLogo from 'img/voodoomtn_new.png';

import styles from './Header.module.scss';

const LinkWrapper = ({ name, to }) => (
	<div className={styles.linkWrapper}>
		<Link className={styles.link} to={to}>
			{name}
		</Link>
	</div>
);

const Header = () => {
	const history = useHistory();
	const cart = CartContainer.useContainer();
	return (
		<div className={styles.header}>
			<div className={styles.logo}>
				<img
					height='60px'
					src={VoodooLogo}
					alt='Voodoo Mountain Logo'
					onClick={() => history.push('/')}
					style={{ cursor: 'pointer' }}
				/>
			</div>
			<div className={styles.links}>
				<LinkWrapper name='Home' to='/' />
				{/* <LinkWrapper name='About' to='/about' disabled /> */}
				<LinkWrapper name='Shop' to='/shop' />
				{/* <LinkWrapper name='Sign In/Register' to='/register' disabled /> */}
				<LinkWrapper name='For Vendors' to='/vendors' />
				<LinkWrapper
					name={
						<span className={styles.cart}>
							<ShoppingCart />
							{cart.cartItems.length > 0 &&
								`(${cart.cartItems.length})`}
						</span>
					}
					to='/cart'
				/>
			</div>
		</div>
	);
};

export default Header;
