import faker from '@faker-js/faker';

// Just to store functions for getting fake demo info
const getDummyProduct = (i) => {
	const price = faker.commerce.price();
	return {
		id: faker.datatype.number(),
		vendorId: dummyStores[i].id,
		companyName: dummyStores[i].name,
		name: faker.commerce.productName(),
		description: faker.commerce.productDescription(),
		imageUrls: getDummyImages(),
		fullPrice: price,
		tags: [
			faker.commerce.productAdjective(),
			faker.commerce.productAdjective(),
			faker.commerce.productAdjective(),
		],
		sale: {
			endDate: faker.date.soon(),
			percentage: (Math.random() / 2) * 100,
		},
	};
};

const getDummyImages = () => {
	return [
		`${faker.image.nature()}?random=${Math.round(Math.random() * 1000)}`,
		`${faker.image.nature()}?random=${Math.round(Math.random() * 1000)}`,
		`${faker.image.nature()}?random=${Math.round(Math.random() * 1000)}`,
		`${faker.image.nature()}?random=${Math.round(Math.random() * 1000)}`,
		`${faker.image.nature()}?random=${Math.round(Math.random() * 1000)}`,
	];
};

const getDummyImagesFromS3 = () => {
	return [
		`https://voodoomtn-products.s3.us-east-2.amazonaws.com/pexels-baihaki-hine-4314203.jpg`,
		`https://voodoomtn-products.s3.us-east-2.amazonaws.com/pexels-craig-adderley-1467574.jpg`,
		`https://voodoomtn-products.s3.us-east-2.amazonaws.com/pexels-rodnae-productions-6655480.jpg`,
	];
};

const getDummyStore = () => ({
	id: faker.random.number(),
	name: faker.company.companyName(),
	description: faker.company.catchPhrase(),
	phone: faker.phone.phoneNumber(),
	email: faker.internet.email(),
	streetAddress: faker.address.streetAddress(),
	city: faker.address.city(),
	state: faker.address.state(),
	zip: faker.address.zipCode(),
});

const dummyStores = [];
for (let i = 0; i < 5; i++) {
	dummyStores.push(getDummyStore());
}

const dummyProducts = [];
for (let i = 0; i < 15; i++) {
	// request to dynamoDB
	dummyProducts.push(getDummyProduct(i % 5));
}

export { dummyStores, dummyProducts, getDummyImages, getDummyImagesFromS3 };
