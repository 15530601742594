import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import ProductCard from 'components/ProductCard';

import styles from './views.module.scss';
import { dummyProducts, dummyStores } from 'utils/fakerService';

const Vendor = () => {
	const { id } = useParams();
	const [vendor, setVendor] = useState(dummyStores.find((s) => s.id == id));
	const [vendorProducts, setVendorProducts] = useState(
		dummyProducts.filter((p) => p.vendorId == id)
	);

	useEffect(() => {
		// 	axios.get(`http://localhost:3001/vendors/${id}`).then((data) => {
		// 		window.scrollTo(0, 0);
		// 		setVendor(data.data);
		// 	});
		window.scrollTo(0, 0);
	}, [id]);

	// useEffect(() => {
	// 	if (vendor) {
	// 		axios
	// 			.get(`http://localhost:3001/products/`, {
	// 				params: {
	// 					vendorId: vendor.id,
	// 					pageSize: 5,
	// 				},
	// 			})
	// 			.then((data) => {
	// 				window.scrollTo(0, 0);
	// 				setVendorProducts(data.data);
	// 			});
	// 	}
	// }, [vendor]);

	if (!vendor) return <div>loading</div>;
	return (
		<div className={styles.vendor}>
			<div className={styles.vendorDetails}>
				<h1>{vendor.name}</h1>
				<a href={'mailto:' + vendor.email}>{vendor.email}</a>
				<h5>{vendor.phone}</h5>
				<div>{vendor.description}</div>
				<div className={styles.cardsContainer}>
					{Object.keys(vendorProducts).length > 0 ? (
						vendorProducts.map((vendorProduct) => (
							<ProductCard
								product={vendorProduct}
								key={vendorProduct.id}
							/>
						))
					) : (
						<div>loading products</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Vendor;
