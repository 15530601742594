import React from 'react';
import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';
import styles from './ImageViewer.module.scss';

const ImageViewer = ({ imageUrls, style }) => {
	return (
		<div>
			<Slide style={style} transitionDuration={200} indicators>
				{imageUrls.map((url) => (
					<div className={styles.slide} key={url}>
						<div style={{ backgroundImage: `url(${url})` }}></div>
					</div>
				))}
			</Slide>
		</div>
	);
};

export default ImageViewer;
