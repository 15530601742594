/**
 * Use when user is coming from logged in situation, expects token in url param
 */
import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';
import axios from 'axios';

const Login = () => {
    const { code } = parse(window.location.search);

    useEffect(() => {
        if (code) {
            axios
                .get(`http://localhost:3001/login/validate/${code}`)
                .then((data) => {
                    window.scrollTo(0, 0);
                });
        }
    }, [code]);

    return <div>Got the token: {code}</div>;
};

export default Login;
