import React from 'react';

import styles from './ButtonInput.module.scss';

const ButtonInput = ({ label, onClick, disabled, style = {} }) => (
	<input
		className={styles.customButton}
		style={style}
		value={label}
		type='button'
		name={label}
		onClick={(e) => onClick(e)}
		disabled={disabled}
	/>
);

export default ButtonInput;
