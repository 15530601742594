import React from 'react';

import CartContainer from 'context/CartContainer';
import Button from 'components/ButtonInput';
import styles from 'views/views.module.scss';

const Cart = () => {
	const cart = CartContainer.useContainer();
	return (
		<div className={styles.cart}>
			<div className={styles.items}>
				{cart.cartItems.length === 0 && (
					<div className={styles.noItems}>
						No items in your cart yet...let's change that!
					</div>
				)}
				{cart.cartItems.map((product) => (
					<div className={styles.cartItem}>
						<img
							className={styles.cartImage}
							src={product.imageUrls[0]}
						/>
						<div className={styles.productName}>{product.name}</div>
						<div className={styles.productQuantity}>
							Quantity: {product.quantity}
						</div>
						<div className={styles.productPrice}>
							{product.sale.percentage &&
							Date.parse(product.sale.endDate) > new Date() ? (
								<div>
									$
									{(
										product.fullPrice -
										(product.sale.percentage / 100) *
											product.fullPrice
									).toFixed(2) * product.quantity}
								</div>
							) : (
								<div>${product.fullPrice}</div>
							)}
						</div>
					</div>
				))}
			</div>
			<div className={styles.checkoutBox}>
				<Button
					onClick={() => window.alert("Let's checkout!")}
					label='Checkout'
					disabled={cart.cartItems.length === 0}
					style={{ width: '100%' }}
				/>
			</div>
		</div>
	);
};

export default Cart;
