import React from 'react';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';

import ImageViewer from 'components/ImageViewer';

import styles from './ProductCard.module.scss';
import { renderer } from '../../utils/product';

const ProductCard = ({ product }) => {
	const history = useHistory();

	return (
		<div className={styles.card}>
			<div className={styles.image}>
				<ImageViewer
					style={{ maxWidth: '100%' }}
					imageUrls={product.imageUrls}
				/>
			</div>
			<div
				className={styles.content}
				onClick={() => history.push(`/product/${product.id}`)}
			>
				<div className={styles.name}>{product.name}</div>
				<div className={styles.prices}>
					{product.sale &&
					Date.parse(product.sale.endDate) > new Date() ? (
						<div>
							<div className={styles.fullPrice}>
								${product.fullPrice}
							</div>
							<div className={styles.discount}>
								-{product.sale.percentage.toFixed(0)}%
							</div>
							<div className={styles.salePrice}>
								$
								{(
									product.fullPrice -
									(product.fullPrice *
										product.sale.percentage) /
										100
								).toFixed(2)}
							</div>
							<div className={styles.saleCountdown}>
								Sale ends:{' '}
								<Countdown
									date={Date.parse(product.sale.endDate)}
									renderer={renderer}
								/>
							</div>
						</div>
					) : (
						<div className={styles.salePrice}>
							${product.fullPrice}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
