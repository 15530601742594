import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from 'views/Home';
import About from 'views/About';
import Product from 'views/Product';
import Vendor from 'views/Vendor';
import Register from 'views/Register';
import VendorRegistration from 'views/VendorRegistration';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Login from 'views/Login';
import Cart from 'views/Cart';

import CartContainer from 'context/CartContainer';

import './App.css';

function App() {
	return (
		<CartContainer.Provider>
			<Router>
				<Header />
				<div className='body'>
					<Switch>
						<Route path='/login'>
							<Login />
						</Route>
						<Route path='/about'>
							<About />
						</Route>
						<Route path='/register'>
							<Register />
						</Route>
						<Route path='/product/:id'>
							<Product />
						</Route>
						<Route path='/vendor/:id'>
							<Vendor />
						</Route>
						<Route path='/vendors'>
							<VendorRegistration />
						</Route>
						<Route path='/cart'>
							<Cart />
						</Route>
						<Route path='/'>
							<Home />
						</Route>
					</Switch>
				</div>
				<Footer />
			</Router>
		</CartContainer.Provider>
	);
}

export default App;
