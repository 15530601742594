import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';

import TextInput from 'components/TextInput';

import styles from './views.module.scss';
import ButtonInput from 'components/ButtonInput/ButtonInput';

const VendorRegistration = () => {
	const [vendorObject, setVendorObject] = useState({});
	const [isFormValid, setIsFormValid] = useState(false);

	useEffect(() => {
		setIsFormValid(validateForm());
	}, [vendorObject]);

	const validateForm = () => {
		return true;
	};

	const submit = () => {
		axios
			.get(`http://localhost:3001/getVendorByName/${vendorObject.companyName}`)
			.then((data) => {
				if (Object.keys(data.data).length === 0) {
					axios.post(`http://localhost:3001/vendor/register`, vendorObject);
				} else {
					alert('Company Name taken');
				}
			});
	};

	const updateField = (fieldName, value) => {
		let existingObj = _.cloneDeep(vendorObject);
		existingObj[fieldName] = value;
		setVendorObject(existingObj);
	};

	return (
		<div className={styles.vendorRegistration}>
			<div className={styles.header}>Sell with us!</div>
			<div className={styles.inputRow}>
				<TextInput
					label='Company Name'
					name='companyName'
					value={vendorObject.companyName}
					onChange={(e) => updateField('companyName', e.target.value)}
				/>
			</div>
			<div className={styles.inputRow}>
				<TextInput
					label='Tell us about your products'
					name='description'
					type='textarea'
					value={vendorObject.description}
					onChange={(e) => updateField('description', e.target.value)}
					rows={10}
					cols={26}
				/>
			</div>
			<div className={styles.inputRow}>
				<TextInput
					label='Company Email'
					name='email'
					type='email'
					value={vendorObject.email}
					onChange={(e) => updateField('email', e.target.value)}
				/>
			</div>
			<div className={styles.inputRow}>
				<TextInput
					label='Company Phone'
					name='phone'
					value={vendorObject.phone}
					onChange={(e) => updateField('phone', e.target.value)}
				/>
			</div>
			<div className={styles.inputRow}>
				<TextInput
					label='Where are you located?'
					name='location'
					value={vendorObject.location}
					onChange={(e) => updateField('location', e.target.value)}
				/>
			</div>
			<div className={styles.inputRow}>
				<TextInput
					label='EIN'
					name='EIN'
					value={vendorObject.EIN}
					onChange={(e) => updateField('EIN', e.target.value)}
				/>
			</div>
			<ButtonInput
				disabled={!isFormValid}
				label='Continue Registration'
				onClick={(e) => submit()}
			/>
		</div>
	);
};

export default VendorRegistration;
