import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';

import ImageGrid from 'components/ImageGrid';
import ProductCard from 'components/ProductCard/ProductCard';
import CartContainer from 'context/CartContainer';
import { renderer } from '../utils/product';

import styles from './views.module.scss';
import 'react-slideshow-image/dist/styles.css';
import { dummyProducts, dummyStars } from 'utils/fakerService';

const Product = () => {
	const cart = CartContainer.useContainer();
	const { id } = useParams();
	const [product, setProduct] = useState(
		dummyProducts.find((p) => p.id == id)
	);
	const history = useHistory();

	useEffect(() => {
		// 	axios.get(`http://localhost:3001/products/${id}`).then((data) => {
		// 		window.scrollTo(0, 0);
		// 		setProduct(data.data);
		// 	});
		window.scrollTo(0, 0);
	}, [id]);

	console.log('yo', dummyProducts);

	if (!product) return <div>loading</div>;
	return (
		<div className={styles.product}>
			<div className={styles.productLayout}>
				<ImageGrid
					imageUrls={product.imageUrls}
					productName={product.name}
				/>
				<div className={styles.productDetails}>
					<div className={styles.productInfoLeft}>
						<h1>{product.name}</h1>
						<h5
							className={styles.companyName}
							onClick={() =>
								history.push(`/vendor/${product.vendorId}`)
							}
						>
							{product.companyName}
						</h5>
						<div>{product.description}</div>
					</div>
					<div className={styles.productInfoRight}>
						{product.sale.percentage &&
						Date.parse(product.sale.endDate) > new Date() ? (
							<Fragment>
								<div className={styles.fullPrice}>
									${product.fullPrice}
								</div>
								<div className={styles.discount}>
									-{product.sale.percentage.toFixed(0)}%
								</div>

								<div className={styles.salePrice}>
									$
									{(
										product.fullPrice -
										(product.sale.percentage / 100) *
											product.fullPrice
									).toFixed(2)}
								</div>
								<div className={styles.saleCountdown}>
									Sale ends:{' '}
									<Countdown
										date={Date.parse(product.sale.endDate)}
										renderer={renderer}
									/>
								</div>
							</Fragment>
						) : (
							<div className={styles.salePrice}>
								${product.fullPrice}
							</div>
						)}
						<button
							className={styles.addToCart}
							onClick={() => cart.addCartItem(product)}
						>
							ADD TO CART
						</button>
					</div>
					{
						// TODO: tag container with tag pills (clickable to see related products)
						// TODO: add price information
						// TODO: add product description here and in API
						// TODO: add related products scroll/bar
						// TODO: add shipping info?
						// TODO: add availability/stock info
						// TODO: add seller link to store
					}
				</div>
			</div>
			<div>
				{/* {product.related.map((relatedProductTag) => (
					<div className={styles.relatedProducts}>
						<h3>
							Products also labelled "{relatedProductTag.tagName}"
						</h3>
						<div className={styles.relatedRow}>
							{relatedProductTag.products.map(
								(relatedProduct) => (
									<div>
										<ProductCard product={relatedProduct} />
									</div>
								)
							)}
						</div>
					</div>
				))} */}
			</div>
		</div>
	);
};

export default Product;
