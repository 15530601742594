import React from 'react';

export const renderer = ({ days, hours, minutes, seconds }) => {
	if (days > 6) {
		return <span>{days} days</span>;
	}
	if (days <= 6 && days >= 1) {
		return <span>{days} days</span>;
	}
	return (
		<span>
			{hours}:{minutes}:{seconds}
		</span>
	);
};
